// material-ui
import { Typography } from '@mui/material';

// project imports
import NavGroup from './NavGroup';
import menuItem from 'menu-items';


import React from 'react';
import { useState } from 'react';
import { Button } from "@mui/material";
import SettingIcon from "../../../../assets/images/sidebar/settings.png";
import SettingsDialog from 'views/templateDashboard/settings';


// ==============================|| SIDEBAR MENU LIST ||============================== //

const MenuList = () => {

    const [openDialog, setOpenDialog] = useState(false);
    const openSettingsDialog = () => {
        setOpenDialog(!openDialog)
    }


    const navItems = menuItem.items.map((item) => {
        switch (item.type) {
            case 'group':
                return <NavGroup key={item.id} item={item} />;
            default:
                return (
                    <Typography key={item.id} variant="h6" color="error" align="center">
                        Menu Items Error
                    </Typography>
                );
        }
    
    });

    return <>
    {
        navItems
    }
        <Button onClick={openSettingsDialog}>
             <img src={SettingIcon} alt ="Settings"/>
        </Button>

           {openDialog && <SettingsDialog onClose={openSettingsDialog} />}

    </>;
};

export default MenuList;
