import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
// project imports
import Loadable from 'ui-component/Loadable';
import MinimalLayout from 'layout/MinimalLayout';

// login option 3 routing
const AuthLogin3 = Loadable(lazy(() => import('views/pages/authentication/authentication3/Login3')));
const AuthRegister3 = Loadable(lazy(() => import('views/pages/authentication/authentication3/Register3')));
const AccountRecovery = Loadable(lazy(() => import('views/pages/authentication/authentication3/AccountRecovery')));
// ==============================|| AUTHENTICATION ROUTING ||============================== //

const AuthenticationRoutes = (isLoggedIn) => {
    return {

        path: '/',
        element: <MinimalLayout />,
        children: [
            {
                path: '/login',
                element: !isLoggedIn ? <AuthLogin3 />: <Navigate to="/dashboard"/>,
            },
            {
                path: '/register',
                element: <AuthRegister3 />
            },
            {
                path: '/account-recovery',
                element: <AccountRecovery />
            }
        ]
    }
};

export default AuthenticationRoutes;
