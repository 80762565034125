import { createRoot } from 'react-dom/client';

// third party
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';

// project imports
import * as serviceWorker from 'serviceWorker';
import App from 'App';
import store from "./store";
import { ApolloProvider, ApolloClient, createHttpLink, InMemoryCache, HttpLink, ApolloLink } from "@apollo/client";
import { RestLink } from 'apollo-link-rest';
import graphqlAuthHeader from '../src/services/graphql-auth-header';
// style + assets
import 'assets/scss/style.scss';
import config from './config';
//import './index.scss';



// ==============================|| REACT DOM RENDER  ||============================== //
// Set `RestLink` with your endpoint
const restLink = new RestLink({ uri: "https://api.agencywatcher.com/api/v1" });
// Set `httpLink`
const httpLink = createHttpLink({
    // uri: "https://api.agencywatcher.com/graphql_dev",
    uri: "https://api.agencywatcher.com/api/v1/graphql",
});
const responseTokenLink = new ApolloLink((operation, forward) => {
    return forward(operation).map((response) => {
      const { response: { headers } } = operation.getContext();
      let userData = JSON.parse(localStorage.getItem('user'));
      if (headers.get('Access-Token') && headers.get('Client') && headers.get('Uid') && userData) {
        userData['access_token'] = headers.get('Access-Token');
        userData['client'] = headers.get('Client');
        userData['uid'] = headers.get('Uid');
        localStorage.setItem("user", JSON.stringify(userData));
      }
      return response;
    });
});
const link = ApolloLink.from([restLink, responseTokenLink, httpLink])
// Setup your client
let client = new ApolloClient({
    cache: new InMemoryCache(),
    link: graphqlAuthHeader.concat(link),
});

client.defaultOptions = {
    watchQuery: {
      fetchPolicy: 'network-only',
      errorPolicy: 'ignore',
    },
    query: {
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
    },
};


const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
    <Provider store={store}>
        <ApolloProvider client={client}>
            <BrowserRouter basename={config.basename}>
                <App />
            </BrowserRouter>
        </ApolloProvider>
    </Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
