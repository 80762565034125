const SET_CONFIRM_DIALOG_POPUP = "SET_CONFIRM_DIALOG_POPUP";

const initialState = {
    isOpen: false,
    isConfirmed: false,
    confirmMessage: null,
};

export default function (state = initialState, action) {
  const { type, toggle, payload, message } = action;
  
  switch (type) {
    case SET_CONFIRM_DIALOG_POPUP:
      return { 
        ...state,
        isOpen: toggle,
        isConfirmed: payload, 
        confirmMessage: message
    };

    default:
      return state;
  }
}