import mainRoutes from './mainRoutes';
import pages from './pages';
import utilities from './utilities';
import other from './other';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
    // items: [dashboard, pages, utilities, other]
    //items: [mainRoutes, other]
    items: [mainRoutes]

};

export default menuItems;
