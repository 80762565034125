import React from "react";
import { 
    Grid,
    Dialog,
    Card,
    Typography,
    TextField,
    Button,
    InputAdornment,
    IconButton
 } from "@mui/material";

import { useState } from "react";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

import { useTheme } from '@mui/material/styles';

const SettingsDialog =({onClose})=>{
    const theme = useTheme();
  
    const handleCancel = () => {
      onClose();
  };

const [title1, setTitle1] = useState('');
const [title2, setTitle2] = useState('');
const [titleError, setTitleError] = useState(false);
const [editIndex, setEditIndex] = useState(null);

const handleEdit = (index) => {
    setEditIndex(index);
    setTitle1(title1);
    setTitle2(title2);
    closeSettingsDialog();
};

    return(
        <Grid>
              <Dialog open={true} onClose={handleCancel} 
              zindex="99"
         sx = {
           {
              position: "absolute",
              top: 0,
              left: 0,
             width: "100%",
             height: "100%",
             backgroundColor: "rgba(0, 0, 0, 0.3)",
             
             
             //display: "flex",
             //alignItems: "center",
             //justifyContent: "center",
           }
         } >
          
            <Grid item px={5} py={3} md={12} 
             sx={{
              //left:'3%',
              width:"100%",
              position:"relative",
              }}>
            <Card>
                
                <Typography  
                    sx={{textAlign:"left",fontWeight:"700",fontSize:"18px",color:"#000"}}>
                    Settings
                </Typography>
                
                <Typography 
                    sx={{textAlign:"left",fontWeight:"400",fontSize:"14px",color:"#000"}}>
                    Rename and Delete
                </Typography>
               
                <Grid container my={7}  columnSpacing={1}>
                    
                    <Grid item md={12} sm={12} xs={12} mx={0} >
                        
                        <TextField
                            sx={{ ...theme.typography.customInput }}
                            margin="dense"
                            label="Dashboard 1"
                            type="text"
                            fullWidth
                            InputProps={{
                            endAdornment: (
                            <InputAdornment position="end">
                              <IconButton edge="end">
                                <EditIcon />
                              </IconButton>
                              <IconButton edge="end" 
                                sx={{"&:hover":{backgroundColor:"#fff"}}}>
                                <DeleteIcon 
                                    sx={{color:"red",marginLeft:"10px"}} />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                             value={title1}
                             onChange={(e) => setTitle1(e.target.value)}
                            error={titleError}
                            helperText={titleError && 'Project Title is required'}
                        />
                    </Grid>

                    <Grid item md={12} sm={12} xs={12} mx={0}>
                       
                         <TextField
                            sx={{ ...theme.typography.customInput }}
                            margin="dense"
                            label="Dashboard 2"
                            type="text"
                            fullWidth
                            InputProps={{
                            endAdornment: (
                            <InputAdornment position="end">
                              <IconButton edge="end">
                                <EditIcon />
                              </IconButton>
                              
                              <IconButton edge="end" 
                                sx={{"&:hover":{backgroundColor:"#fff"}}}>
                                <DeleteIcon 
                                sx={{color:"red",marginLeft:"10px"}} />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                            value={title2}
                            onChange={(e) => setTitle2(e.target.value)}
                            error={titleError}
                            helperText={titleError && 'Title for second dashboard is required'}
                        />
                    </Grid>
                </Grid>
                <Grid sx={{display:"flex",justifyContent:"flex-start"}}>
                  <Button
                        disableRipple disableFocusRipple
                          variant="contained" 
                          size="small"
                        //  href="/template-dashboard"
                          sx={{ my:{md:1,sm:4}, 
                                backgroundColor: '#5204F8',
                                color: '#fff',
                                marginX: "10px",
                                padding: "10px 20px",
                                borderRadius: "10px",
                            "&:hover":{
                                    backgroundColor: "#5204F8"
                                }}}
                          onClick={() =>{
                            handleEdit()
                          }}
                      >
                         Save Changes
                    </Button>
                    <Button
                        disableRipple disableFocusRipple
                          variant="contained" 
                          size="small"
                        //  href="/template-dashboard"
                          sx={{ my:{md:1,sm:4}, 
                                backgroundColor:"#fff",
                                color: '#5204F8',
                                padding: "10px 20px",
                                marginX:"10px",
                                borderRadius: "10px",
                                "&:hover":{
                                    backgroundColor:"#fff"
                                }
                            }}
                            onClick={handleCancel}
                      >
                         Cancel
                    </Button>
                    </Grid>
            </Card>

            
          </Grid>
          
            <Button
              sx={{
                position:"fixed",
                top:"0%",right:"00%",
                '&:hover':{transform:"scale(1.1)"}
              }}
//onClick={handleClose}
                >
{/* <img src={close} alt="cross"/> */}
            </Button>
      </Dialog>

        </Grid>




    );
}
export default SettingsDialog;