import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import { Navigate } from 'react-router-dom';
import SettingsDialog from 'views/templateDashboard/settings';
// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));

// utilities routing
const UtilsTypography = Loadable(lazy(() => import('views/utilities/Typography')));
const UtilsColor = Loadable(lazy(() => import('views/utilities/Color')));
const UtilsShadow = Loadable(lazy(() => import('views/utilities/Shadow')));
const UtilsMaterialIcons = Loadable(lazy(() => import('views/utilities/MaterialIcons')));
const UtilsTablerIcons = Loadable(lazy(() => import('views/utilities/TablerIcons')));

// sample page routing
const Home = Loadable(lazy(() => import('views/project')));
const ReportPage = Loadable(lazy(() => import('views/report-page')));
const Integration = Loadable(lazy(() => import('views/integration')));
// const SettingsDialog = Loadable(lazy(() => import('views/templateDashboard/settings')));

const AIAssistant = Loadable(lazy(() => import('views/ai-assistant')));
const Account = Loadable(lazy(() => import('layout/Account')));

// --------------------------|| Template Dashboard ||-----------------------------------
const Template = Loadable(lazy(() => import('views/templateDashboard/index')));

const CreateTemplateDashboard = Loadable(lazy(() => import('views/templateDashboard/create')));
const OverviewTemplateDashboard = Loadable(lazy(() => import('views/templateDashboard/overview')));
const MainTemplateDashboard = Loadable(lazy(() => import('views/templateDashboard/dashboard')));
const DashboardHistory = Loadable(lazy(() => import('views/templateDashboard/history')));

// --------------------------|| Template Report ||-----------------------------------

const CreateTemplateReport = Loadable(lazy(() => import('views/templateReport/create')));
const OverviewReport = Loadable(lazy(() => import('views/templateReport/overview')));

// -------------------------------|| The Dashboard ||-----------------------------------
const OverviewDashboard = Loadable(lazy(() => import('views/myDashboard/overview')));
const CreateDashboard = Loadable(lazy(() => import('views/myDashboard/create')));
const MainDashboard = Loadable(lazy(() => import('views/myDashboard/dashboard')));

//---------------------------------|| Goals ||----------------------------------------//
const OverviewGoals = Loadable(lazy(() => import('views/Goals/overview')));
const CreateGoals = Loadable(lazy(() => import('views/Goals/create')));

// --------------------------------|| Section ||--------------------------------------
const DashboardSection = Loadable(lazy(() => import('views/section/section')));


//---------------------------------|| Integration ||----------------------------------------//
const IntegrationLog = Loadable(lazy(() => import('views/integration/log')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = (isLoggedIn) => {
   return {
        path: '/',
        element: isLoggedIn ? <MainLayout />: <Navigate to="/" />,
        children: [
            {
                path: '*',
                element: <Navigate to="/dashboard" />
            },
            {
                path: '/dashboard',
                element: <DashboardDefault />
            },
            {
                path: 'utils',
                children: [
                    {
                        path: 'util-typography',
                        element: <UtilsTypography />
                    }
                ]
            },
            {
                path: 'utils',
                children: [
                    {
                        path: 'util-color',
                        element: <UtilsColor />
                    }
                ]
            },
            {
                path: 'utils',
                children: [
                    {
                        path: 'util-shadow',
                        element: <UtilsShadow />
                    }
                ]
            },
            {
                path: 'icons',
                children: [
                    {
                        path: 'tabler-icons',
                        element: <UtilsTablerIcons />
                    }
                ]
            },
            {
                path: 'icons',
                children: [
                    {
                        path: 'material-icons',
                        element: <UtilsMaterialIcons />
                    }
                ]
            },
            {
                path: '/home',
                element: <Home />
            },




            {
                path: '/template',                    
                element: <Template />
            },
            {
                path: '/create-template-dashboard',                    
                element: <CreateTemplateDashboard />
            },
            {
                path: '/overview-template-dashboard',
                element: <OverviewTemplateDashboard />
            },
            {
                path: '/main-template-dashboard',
                element: < MainTemplateDashboard />
            },
            {
                path: '/dashboard-history',
                element: < DashboardHistory />
            },

            
            
            {
                path:'/create-dashboard',
                element: <CreateDashboard />
            },
            {
                path:'/overview-dashboard',
                element:<OverviewDashboard />
            },

            {
                path: '/main-dashboard',
                element: < MainDashboard />
            },
        
            {
                path: '/dashboard-section',
                element: < DashboardSection />
            },
    
            {
                path: '/create-template-report',                    
                element: <CreateTemplateReport />
            },
             {
                path: '/overview-report',
                element: <OverviewReport />
            },


             {
                path: '/overview-goals',
                element: <OverviewGoals />
            },
            {
                path: '/create-goals',
                element: <CreateGoals />
            },
            

            
            {
                path: '/integration-log',
                element: < IntegrationLog />
            },
            {
                path: '/ai-assistant',
                element: <AIAssistant />
            },
            {
                path: '/report-page',
                element: <ReportPage />
            },
            {
                path: '/integration',
                element: <Integration />
            },
            {
                path: '/settings',
                element: <SettingsDialog />
            },
            {
                path: '/account',
                element: <Account />
            }
        ],
        guard: (location) => isLoggedIn
    }
        };
export default MainRoutes;
