import { createStore, applyMiddleware, compose } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
import  promise from 'redux-promise';
import rootReducer from "./reducers";

// ==============================|| REDUX - MAIN STORE ||============================== //

export const persister = 'Free';

const store = createStore(rootReducer, applyMiddleware(promise))


export default store;
