
const REGISTER_SUCCESS = "REGISTER_SUCCESS";
const REGISTER_FAIL = "REGISTER_FAIL";
const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
const RESET_PASSWORD_FAIL = "RESET_PASSWORD_FAIL";
const RECOVER_PASSWORD_SUCCESS = "RECOVER_PASSWORD_SUCCESS";
const RECOVER_PASSWORD_FAIL = "RECOVER_PASSWORD_FAIL";
const DELETE_REGISTRATION_SUCCESS = "DELETE_REGISTRATION_SUCCESS";
const DELETE_REGISTRATION_FAIL = "DELETE_REGISTRATION_FAIL";
const LOGIN_SUCCESS = "LOGIN_SUCCESS";
const LOGIN_FAIL = "LOGIN_FAIL";
const LOGOUT = "LOGOUT";
const CLEAR_AUTH_MESSAGE = "CLEAR_AUTH_MESSAGE";

const user = JSON.parse(localStorage.getItem("user"));

const initialState = {
  isLoggedIn: user ? true : false,
  user,
  authMessage: null
};

export default function (state = initialState, action) {
  const { type, payload, message } = action;

  switch (type) {
    case REGISTER_SUCCESS:
      return {
        ...state,
        isLoggedIn: false,
        authMessage: message
      };
    case REGISTER_FAIL:
      return {
        ...state,
        isLoggedIn: false,
        authMessage: message
      };
    case RESET_PASSWORD_SUCCESS:
        return {
          ...state,
          authMessage: message
        };
    case RESET_PASSWORD_FAIL:
        return {
          ...state,
          authMessage: message
        };
    case RECOVER_PASSWORD_SUCCESS:
        return {
          ...state,
          authMessage: message
        };
    case RECOVER_PASSWORD_FAIL:
        return {
          ...state,
          authMessage: message
        };
    case DELETE_REGISTRATION_SUCCESS:
      return {
        ...state,
        isLoggedIn: false,
        authMessage: message
      };
    case DELETE_REGISTRATION_FAIL:
      return {
        ...state,
        authMessage: message
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        isLoggedIn: true,
        user: payload,
        authMessage: ""
      };
    case LOGIN_FAIL:
      return {
        ...state,
        isLoggedIn: false,
        user: null,
        authMessage: message
      };
    case LOGOUT:
      return {
        ...state,
        isLoggedIn: false,
        user: null,
        authMessage: ""
      };
    case CLEAR_AUTH_MESSAGE:
      return { 
        authMessage: "" 
      };
    default:
      return state;
  }
}