
// assets
//import {IconDashboard} from '@tabler/icons';

// constant
//const icons = {IconDashboard};
import ReportIcon from '@mui/icons-material/Report';
import ArticleIcon from '@mui/icons-material/Article';
import IntegrationInstructionsIcon from '@mui/icons-material/IntegrationInstructions';
import HomeIcon from '@mui/icons-material/Home';
import TableViewIcon from '@mui/icons-material/TableView';
import DashboardIcon from '@mui/icons-material/Dashboard';
import AssistantIcon from '@mui/icons-material/Assistant';
import SettingsIcon from '@mui/icons-material/Settings';
import CrisisAlertIcon from '@mui/icons-material/CrisisAlert';

import IntegrationIcon from "../assets/images/sidebar/integration.png";
// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const mainRoutes = {
    id: 'dashboard',
    // title: 'Dashboard',
    type: 'group',
    children: [{
            id: 'home',
            //title: 'Home',
            type: 'item',
            url: '/home',
            icon: HomeIcon,
            breadcrumbs: false
        },
        {
            id: 'template',
            //title: 'Template',
            type: 'item',
            url: '/template',
            icon: TableViewIcon,
            breadcrumbs: false
        },
        {
            id: 'default',
            //title: 'Dashboard',
            type: 'item',
            url: '/dashboard',
            icon: DashboardIcon,
            breadcrumbs: false
        },
        {
            id: 'ai-assistant',
            // title: 'AI-Assistant',
            type: 'item',
            url: '/overview-dashboard',
            icon: AssistantIcon,
            breadcrumbs: false
        },
        {
            id: 'dashboard-history',
            //title: 'Report',
            type: 'item',
            url: '/dashboard-history',
            icon: ArticleIcon,
            breadcrumbs: false
        },
        {
            id: 'integration-page',
            // title: 'Integration',
            type: 'item',
            url: '/integration',
            icon: IntegrationInstructionsIcon,
            
            breadcrumbs: false
        },
        {
            id: 'goals',
            // title: 'Integration',
            type: 'item',
            url: '/overview-goals',
            icon: CrisisAlertIcon,
            breadcrumbs: false
        },
    ]
};

export default mainRoutes;

