import axios from "axios";
import authHeader from "./auth-header";
import jwtInterceptor from './auth-interceptor';

const API_URL = "https://api.agencywatcher.com/api/v1";

const register = (first_name, last_name, email, phone_number, password, password_confirmation) => {
  return axios.post(API_URL + "/auth/", {
    first_name,
    last_name,
    email,
    phone_number,
    password,
    password_confirmation,
  })
};

const resetPassword = (current_password, password_confirmation, password) => {
  return jwtInterceptor.put(API_URL + "/auth/password", {
    current_password,
    password_confirmation,
    password
  })
};

const recoverPassword = (email, redirect_url) => {
  return axios.post(API_URL + "/auth/password", {
    email,
    redirect_url,
  })
};

const deleteRegistration = () => {
  return jwtInterceptor.delete(API_URL + "/auth")
};

const login = (email, password) => {
  return axios.post(API_URL + "/auth/sign_in", {
      email,
      password,
  })
};

const logout = () => {
  return jwtInterceptor.delete(API_URL + "/auth/sign_out")
};

export const AuthService = {
  register,
  resetPassword,
  recoverPassword,
  deleteRegistration,
  login,
  logout,
};