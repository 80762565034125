import { useSelector, useDispatch } from 'react-redux';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Box,
    IconButton,
    Typography,
  } from '@mui/material';
  import { Close } from '@mui/icons-material';
  import { setConfirmDialogPopup } from '../../store/actions/ConfirmDialogActions';

const ConfirmDialogPopup = () => {
  const dispatch = useDispatch();
  const ConfirmDialog  = useSelector(state => state.ConfirmDialog);

    return (
      <Dialog open={ConfirmDialog.isOpen} maxWidth="sm" fullWidth>
        <DialogTitle>Confirm the action</DialogTitle>
        <Box position="absolute" top={0} right={0}>
          <IconButton onClick={() => {dispatch(setConfirmDialogPopup(!ConfirmDialog.isOpen, false, null))}}>
            <Close />
          </IconButton>
        </Box>
        <DialogContent>
          <Typography>{ConfirmDialog.confirmMessage}</Typography>
        </DialogContent>
        <DialogActions>
          <Button color="primary" variant="contained" onClick={() => {dispatch(setConfirmDialogPopup(!ConfirmDialog.isOpen, false, null))}}>
            Cancel
          </Button>
          <Button color="secondary" variant="contained" onClick={() => {dispatch(setConfirmDialogPopup(!ConfirmDialog.isOpen, true, ConfirmDialog.confirmMessage))}}>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    );
  };
  
  export default ConfirmDialogPopup;