import { setContext } from '@apollo/client/link/context';


const graphqlAuthHeader = setContext((_, { headers }) => {
    // Get the JWT from local storage
    const user = JSON.parse(localStorage.getItem('user'));
    // return the headers to the context so httpLink can read them
   
    return {
        headers: {
        ...headers,
        'Access-Token': user['access_token'] ? user['access_token'] : "",
        'Client':  user['client'] ? user['client'] : "",
        'Uid': user['uid'] ? user['uid'] : "",
        }
    }
    
});

export default graphqlAuthHeader;