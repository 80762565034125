import { useRoutes } from 'react-router-dom';
import store from '../store';
// routes
import MainRoutes from './MainRoutes';
import AuthenticationRoutes from './AuthenticationRoutes';
import IntroRoutes from './IntroRoutes';
import TestRoutes from './TestRoutes';

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
    const isLoggedIn = store.getState().auth.isLoggedIn;
    return useRoutes(
        [
            IntroRoutes(isLoggedIn), 
            MainRoutes(isLoggedIn), 
            AuthenticationRoutes(isLoggedIn),
            //TestRoutes(isLoggedIn),
        ]);
}
