import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
// project imports
import Loadable from 'ui-component/Loadable';
import MinimalLayout from 'layout/MinimalLayout';

// login option 3 routing
const OverviewDashboard = Loadable(lazy(() => import('views/myDashboard/overview')));
const CreateDashboard = Loadable(lazy(() => import('views/myDashboard/create')));
const MainDashboard = Loadable(lazy(() => import('views/myDashboard/dashboard')));

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const TestRoutes = (isLoggedIn) => {
    return {

        path: '/',
        element: <TestRoutes />,
        children: [
            
            {
                path:'/create-dashboard',
                element: !isLoggedIn ?<CreateDashboard />: <Navigate to="/dashboard"/>,
            },
            {
                path:'/overview-dashboard',
                element: !isLoggedIn ? <OverviewDashboard />: <Navigate to="/dashboard"/>,
            },
              
            {
                path: '/main-dashboard',
                element: !isLoggedIn ? < MainDashboard />: <Navigate to="/dashboard"/>,
            },
        ]
    }
};

export default TestRoutes;
