import { AuthService } from '../../services/auth.service';

const REGISTER_SUCCESS = "REGISTER_SUCCESS";
const REGISTER_FAIL = "REGISTER_FAIL";
const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
const RESET_PASSWORD_FAIL = "RESET_PASSWORD_FAIL";
const RECOVER_PASSWORD_SUCCESS = "RECOVER_PASSWORD_SUCCESS";
const RECOVER_PASSWORD_FAIL = "RECOVER_PASSWORD_FAIL";
const DELETE_REGISTRATION_SUCCESS = "DELETE_REGISTRATION_SUCCESS";
const DELETE_REGISTRATION_FAIL = "DELETE_REGISTRATION_FAIL";
const LOGIN_SUCCESS = "LOGIN_SUCCESS";
const LOGIN_FAIL = "LOGIN_FAIL";
const LOGOUT = "LOGOUT";


export const registerAction = (first_name, last_name, email, phone_number, password, password_confirmation)  => {
  return AuthService.register(first_name, last_name, email, phone_number, password, password_confirmation)
  .then((response) => {
    return {
      type: REGISTER_SUCCESS,
      message: "User registered successfully!"
    }
  })
  .catch((error) => {
    return {
      type: REGISTER_FAIL,
      message: error.response.data.errors.full_messages[0]
    }
  })  
};

export const resetPassword = (current_password, password_confirmation, password)  => {
  return AuthService.resetPassword(current_password, password_confirmation, password)
  .then((response) => {
    return {
      type: RESET_PASSWORD_SUCCESS,
      message: "Your password has been successfully updated!"
    }
  })
  .catch((error) => {
    return {
      type: RESET_PASSWORD_FAIL,
      message: error.errors.full_messages
    }
  })  
};

export const recoverPassword = (email, url)  => {
  return AuthService.recoverPassword(email, url)
  .then((response) => {
    return {
      type: RECOVER_PASSWORD_SUCCESS,
      message: "Your request has been sent successfully!"
    }
  })
  .catch((error) => {
    return {
      type: RECOVER_PASSWORD_FAIL,
      message: "your recover password request failed"
    }
  })  
};

export const deleteRegistrationAction = ()  => {
  return AuthService.deleteRegistration()
  .then((response) => {
    localStorage.removeItem("user");
    return {
      type: DELETE_REGISTRATION_SUCCESS,
      message: "Delete registration successfully!"
    }
  })
  .catch((error) => {
    return {
      type: DELETE_REGISTRATION_FAIL,
      message: "Could not delete registration"
    }
  })  
};

export const loginAction = (email, password)  => {
  return AuthService.login(email, password)
  .then((response) => {
    let userData = response.data;
    userData['access_token'] = response.headers['access-token'];
    userData['client'] = response.headers['client'];
    userData['uid'] = response.headers['uid'];
    localStorage.setItem("user", JSON.stringify(userData));
    return {
      type: LOGIN_SUCCESS,
      payload: { user: userData },
    }
  })
  .catch((error) => {
    return {
      type: LOGIN_FAIL,
      message: error.response.data.errors[0]
    }
  })
};

export const logoutAction = ()  => {
  return AuthService.logout()
  .then(() => {
    localStorage.removeItem("user");
    return {
      type: LOGOUT,
    }
  })
  .catch((error) => {
    localStorage.removeItem("user");
    return {
      type: LOGOUT,
    }
  })  
};