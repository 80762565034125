import { lazy } from 'react';

// project imports
import Loadable from 'ui-component/Loadable';
import { Navigate } from 'react-router-dom';


const HomePage = Loadable(lazy(() => import('Homepage/Homepage')));
const IntegrationPageDetail = Loadable(lazy(() => import('Homepage/IntegrationPage/IntegrationPage')));
const ReportPageDetail = Loadable(lazy(() => import('Homepage/Report Page/ReportPage')));
const Pricing  = Loadable(lazy(() => import('Homepage/Pricing Page/PricingPage')));
const Feature = Loadable(lazy(() => import('Homepage/Feature/feature')));
// ==============================|| MAIN ROUTING ||============================== //

const IntroRoutes = (isLoggedIn) => {

    return {
        path: '/',
        children: [
            {
                path: '/',
                element: !isLoggedIn ? <HomePage />: <Navigate to="/dashboard"/>,
            },
            {
                path: '/feature',
                element: !isLoggedIn ? <Feature />: <Navigate to="/dashboard"/>,
            },
            {
                path: '/integration-detail',
                element: !isLoggedIn ? <IntegrationPageDetail />: <Navigate to="/dashboard"/>,
            },
            {
                path: '/report-detail',
                element: !isLoggedIn ? <ReportPageDetail />: <Navigate to="/dashboard"/>,
            },
            {
                path: '/pricing',
                element: !isLoggedIn ? <Pricing />: <Navigate to="/dashboard"/>,
            },
        ],
    }
};
export default IntroRoutes;
