
import { combineReducers } from "redux";
import AuthReducer from "./AuthReducer";
import MessageReducer from "./MessageReducer";
import customizationReducer from '../customizationReducer';
import ConfirmDialogReducer from './ConfirmDialogReducer';

const rootReducers = combineReducers({
	auth: 			AuthReducer,
	message: 		MessageReducer,
	ConfirmDialog: ConfirmDialogReducer,
    customization: customizationReducer,
});

export default rootReducers;